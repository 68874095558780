import React from "react";
import {Button, CircularProgress, Dialog, DialogTitle, Divider, Stack} from "@mui/material";
import {getApi} from "../util/api";
import ReviewListItem from "./reviewListItem";
import CloseButton from "./closeButton";
import {logEvent} from "firebase/analytics";
import ReviewFilterContainer from "./filter/reviewContainer";
import {parseFacilities, parseOrderBy} from "./filter/reviewParser";

const pageSize = 5;

const defaultDummy = {"timestamp":1674148409392,"data":{"total":6,"review":[{"id":"63c1301947a1140af1fd515d","poiId":"63b5b6903fa9f727bfb6bcc9","userId":"63c1300947a1140af1fd5153","user":{"id":"63c1300947a1140af1fd5153","name":"Anita Wong"},"rating":{"overall":5,"food":5,"crowdedness":5,"seats":5},"facilities":{"highChair":"yes","childrenMeal":"yes"},"comment":"happy","createdAt":1673605145907,"lastUpdateAt":1673605145907},{"id":"63c12fec47a1140af1fd5147","poiId":"63b5b6903fa9f727bfb6bcc9","userId":"63c12fe247a1140af1fd513d","user":{"id":"63c12fe247a1140af1fd513d","name":"Anitatatatatatatatatatatatatatatatatatatatatatatata"},"rating":{"overall":5,"food":5,"crowdedness":5,"seats":5},"facilities":{"highChair":"yes","childrenMeal":"yes"},"comment":"","createdAt":1673605100223,"lastUpdateAt":1673605100223},{"id":"63c12fc947a1140af1fd5131","poiId":"63b5b6903fa9f727bfb6bcc9","userId":"63c12fb547a1140af1fd5124","user":{"id":"63c12fb547a1140af1fd5124","name":"黃芷珊"},"rating":{"overall":5,"food":5,"crowdedness":5,"seats":5},"facilities":{"highChair":"yes","childrenMeal":"yes"},"comment":"very good","createdAt":1673605065520,"lastUpdateAt":1673605065520},{"id":"63c12f3869bf8199b9599820","poiId":"63b5b6903fa9f727bfb6bcc9","userId":"63c12f2969bf8199b9599813","user":{"id":"63c12f2969bf8199b9599813","name":"Anita 1"},"rating":{"overall":4,"food":4,"crowdedness":4,"seats":4},"facilities":{"highChair":"yes","childrenMeal":"yes"},"comment":"fair","createdAt":1673604920388,"lastUpdateAt":1673604920388},{"id":"63c12ee169bf8199b95997ff","poiId":"63b5b6903fa9f727bfb6bcc9","userId":"63b71324533434bf4ecbf4ed","user":{"id":"63b71324533434bf4ecbf4ed","name":"Anita 2"},"rating":{"overall":4,"food":4,"crowdedness":4,"seats":4},"facilities":{"highChair":"yes","childrenMeal":"yes"},"comment":"good","createdAt":1673604833590,"lastUpdateAt":1674111363668}]}};

const callApi = (id, offset, orderBy, facilitiesHighChair, facilitiesChildrenMeal, successCallback, errorCallback, dummy = defaultDummy) => {
    getApi("api/poi/review/?poiId="+id+"&limit="+pageSize+"&offset="+offset+parseOrderBy(orderBy)+parseFacilities(facilitiesHighChair, facilitiesChildrenMeal), (result) => {
        if (result.data) {
            successCallback(result.data);
        }}, (error) => errorCallback(error), dummy);
}

export default class ReviewListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingMore: false,
            reviews: [],
            total: 0,
            offset: 0,
            orderBy: "latestCreated",
            facilitiesHighChair: false,
            facilitiesChildrenMeal: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldInit = prevProps.init;
        const newInit = this.props.init;
        if (!oldInit && newInit) {
            const {item, analytics} = this.props;
            const {offset, orderBy, facilitiesHighChair, facilitiesChildrenMeal} = this.state;
            logEvent(analytics, 'review.list', {poiId: item.id});
            this.setState({loading: true});
            callApi(item.id, offset, orderBy, facilitiesHighChair, facilitiesChildrenMeal, data => {
                const reviews = data.review;
                this.setState({
                    reviews: reviews,
                    total: data.total,
                    loading: false,
                });
            }, err => {});
        }
    }

    render() {
        const {item, open, handleClose, analytics} = this.props;
        const {reviews, total, offset, orderBy, facilitiesHighChair, facilitiesChildrenMeal, loading, loadingMore} = this.state;

        const loadMore = () => {
            const newOffset = offset+5;
            logEvent(analytics, 'review.list.more', {poiId: item.id, offset: newOffset});
            this.setState({loadingMore: true});
            callApi(item.id, newOffset, orderBy, facilitiesHighChair, facilitiesChildrenMeal, data => {
                const reviews = data.review;
                this.setState(prevState => {
                    return {
                        reviews: prevState.reviews.concat(reviews),
                        total: data.total,
                        loadingMore: false,
                    };
                });
            }, err => {
                this.setState({loadingMore: false});
            }, {"timestamp":1674148466034,"data":{"total":6,"review":[{"id":"63b5bb96819eea32a33064ca","poiId":"63b5b6903fa9f727bfb6bcc9","userId":"63adc7b06ac3425eb22fe363","user":{"id":"63adc7b06ac3425eb22fe363","name":"Anita 3"},"rating":{"overall":4,"food":4,"crowdedness":5,"seats":5},"facilities":{"highChair":"yes","childrenMeal":"no"},"comment":"not bad","createdAt":1672854422237,"lastUpdateAt":1674148354660}]}});
        }

        const reload = (orderBy, facilitiesHighChair, facilitiesChildrenMeal) => {
            this.setState({loading: true});
            callApi(item.id, offset, orderBy, facilitiesHighChair, facilitiesChildrenMeal, data => {
                const reviews = data.review;
                this.setState({
                    reviews: reviews,
                    total: data.total,
                    loading: false,
                });
            }, () => {});
        }

        const handleChangeOrder = (event) => {
            const orderBy = event.target.value;
            this.setState({orderBy: orderBy}, ()=>reload(orderBy, facilitiesHighChair, facilitiesChildrenMeal));
        }

        const handleUpdateFacilitiesHighChair = (event) => {
            const checked = event.target.checked;
            this.setState({facilitiesHighChair: checked}, () => reload(orderBy, checked, facilitiesChildrenMeal));
        }
        const handleUpdateFacilitiesChildrenMeal = (event) => {
            const checked = event.target.checked;
            this.setState({facilitiesChildrenMeal: checked}, () => reload(orderBy, facilitiesHighChair, checked));
        }

        return (
            <Dialog onClose={handleClose} open={open}>
                {loading ?
                    <div className="review-list-loading"><CircularProgress /></div> :
                    <div>
                        <DialogTitle>
                            <div className="dialog-close">
                                <div className="review-title">
                                    <span>餐廳評分 - </span><span>{item.name} </span><span>(共{total}個)</span>
                                </div>
                                <CloseButton handleClose={handleClose}/>
                            </div>
                        </DialogTitle>
                        <ReviewFilterContainer orderBy={orderBy} handleChangeOrder={handleChangeOrder} facilitiesHighChair={facilitiesHighChair} facilitiesChildrenMeal={facilitiesChildrenMeal} handleUpdateFacilitiesHighChair={handleUpdateFacilitiesHighChair} handleUpdateFacilitiesChildrenMeal={handleUpdateFacilitiesChildrenMeal}/>
                        <Divider/>
                        <Stack>
                            {reviews.map(review => {
                                return (
                                <div>
                                <ReviewListItem review={review} key={review.id} type="poi"/>
                                </div>
                                )})}
                            {loadingMore ?
                                <div className="review-list-loading-more"><CircularProgress /></div> :
                                total>reviews.length ?
                                <Button onClick={loadMore}>更多</Button>
                                : <div/>
                            }
                        </Stack>
                    </div>
                }
            </Dialog>
        );
    };
}