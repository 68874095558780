import React from 'react';
import {Alert, Button, CircularProgress, Dialog, Rating, TextField, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {getApi, postApi} from "../util/api";
import DialogTitleWithClose from "./dialogTitleWithClose";
import {logScreenView} from "../util/analytics";
import {logEvent} from "firebase/analytics";

class RatingInput extends React.Component {
    render() {
        const {name, field, setResult, value} = this.props;

        const updateValue = (event, value) => {
            setResult(field, value);
        };

        return (
            <div className="item-section">
                <Typography variant="subtitle1">
                    {name}:
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                    <div>
                        <Rating value={value} precision={1} size="large" onChange={updateValue}/>
                    </div>
                </Typography>
            </div>
        )
    }
}

class FacilityInput extends React.Component {
    render() {
        const {name, field, setResult, value} = this.props;

        const updateValue = (value) => {
            setResult(field, value);
        };

        return (
            <div className="item-section">
                <Typography variant="subtitle1">
                    {name}:
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" className="facility-row">
                    {value && value === "yes" ?
                        <CheckCircleIcon className="facility-yes-icon" fontSize="large"/> :
                        <CheckCircleOutlineIcon onClick={()=>updateValue("yes")} fontSize="large"/>
                    }
                    {value && value === "no" ?
                        <CancelIcon className="facility-no-icon" fontSize="large"/> :
                        <CancelOutlinedIcon fontSize="large" onClick={()=>updateValue("no")}/>
                    }
                </Typography>
            </div>
        )
    }
}

export default class ReviewContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            loading: false,
            success: false,
            alreadySubmitted: false,

            rating: {
                overall: null,
                food: null,
                crowdedness: null,
                seats: null,
            },
            facilities: {
                highChair: null,
                childrenMeal: null
            },
            comment: ""
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldInit = prevProps.init;
        const newInit = this.props.init;
        if (!oldInit && newInit) {
            const {item, analytics} = this.props;
            logScreenView(analytics, 'review', {poiId: item.id});
            getApi("api/poi/review/"+item.id, (result) => {
                if (result.data)
                    this.setState({
                        alreadySubmitted: true,
                        rating: result.data.rating,
                        facilities: result.data.facilities,
                        comment: result.data.comment,
                    });
            }, (error) => {
            }, {"timestamp":1674148260034,"data":{"id":"63b5bb96819eea32a33064ca","poiId":"63b5b6903fa9f727bfb6bcc9","userId":"63adc7b06ac3425eb22fe363","rating":{"overall":4,"food":5,"crowdedness":5,"seats":5},"facilities":{"highChair":"yes","childrenMeal":"no"},"comment":"not bad","createdAt":1672854422237,"lastUpdateAt":1672854422237}});
        }
    }

    render() {
        const {item, open, handleClose, handleUpdateSuccess, analytics} = this.props;
        const { errorMessage, loading, success, alreadySubmitted,
            rating, facilities, comment } = this.state;

        const setRating = (field, result) => this.setState(prevState => {
            let rating = { ...prevState.rating };
            rating[field] = result;
            return {rating};
        });

        const setFacility = (field, result) => this.setState(prevState => {
            let facilities = { ...prevState.facilities };
            facilities[field] = result;
            return {facilities};
        });

        const setComment = (event) => this.setState({comment: event.target.value});

        const setError = (errorMessage) => this.setState({
            loading: false,
            errorMessage: errorMessage,
        });

        const review = () => {
            logEvent(analytics, 'review.submit', {poiId: item.id});
            this.setState({loading: true});
            postApi("api/poi/review/create", {
                poiId: item.id,
                rating: rating,
                facilities: facilities,
                comment: comment
            }, result => {
                if (result.error) {
                    setError(result.error.message);
                }
                else {
                    this.setState({success: true});
                    handleUpdateSuccess(result.data.poi, result.data.review);
                    // handleClose();
                }
            }, err => {
                setError(err);
            }, {"timestamp":1674148354907,"data":{"poi":{"id":"63b5b6903fa9f727bfb6bcc9","name":"無印良品 新城市廣場店","address":"香港沙田沙田正街2-8號新城市廣場3期2樓A221號","types":["home_goods_store","store","point_of_interest","establishment"],"ratingAverage":{"overall":4.5,"food":4.5,"crowdedness":4.666666666666667,"seats":4.666666666666667},"facilities":{"highChair":{"exist":"yes","yesCount":6,"noCount":0},"childrenMeal":{"exist":"yes","yesCount":5,"noCount":1}},"website":"https://www.muji.com/hk/","totalReview":6,"createdAt":1672853136206,"lastUpdateAt":1674148354823},"review":{"id":"63b5bb96819eea32a33064ca","poiId":"63b5b6903fa9f727bfb6bcc9","userId":"63adc7b06ac3425eb22fe363","rating":{"overall":4,"food":4,"crowdedness":5,"seats":5},"facilities":{"highChair":"yes","childrenMeal":"no"},"comment":"not bad","createdAt":1672854422237,"lastUpdateAt":1674148354660}}});
        }

        const closeDo = () => {
            handleClose();
            if (success) {
                new Promise(r => setTimeout(r, 1000))
                    .then(() => {
                        this.setState({
                            errorMessage: null,
                            loading: false,
                            success: false,
                        });
                    });
            }
        }

        const title = "餐廳評分 - "+item.name;
        return (
            <Dialog onClose={closeDo} open={open}>
                <DialogTitleWithClose title={title} handleClose={handleClose}/>
                {errorMessage ? (<Alert severity="error">{errorMessage}</Alert>) : (<div/>)}

                {success ?
                    <div className="review-container-success">
                        <Typography>
                            成功提交評分，感謝你！
                        </Typography>
                    </div>
                    :
                    <div className="review-container">
                        <RatingInput name="整體評分" field="overall" setResult={setRating} value={rating.overall}/>
                        <RatingInput name="食物合適度" field="food" setResult={setRating} value={rating.food}/>
                        <RatingInput name="座位寬敞度" field="crowdedness" setResult={setRating} value={rating.crowdedness}/>
                        <RatingInput name="座位舒適度" field="seats" setResult={setRating} value={rating.seats}/>

                        <FacilityInput name="BB凳/高凳" field="highChair" setResult={setFacility} value={facilities.highChair}/>
                        <FacilityInput name="兒童餐" field="childrenMeal" setResult={setFacility} value={facilities.childrenMeal}/>

                        <TextField
                            id="review-comment"
                            label="評語"
                            multiline
                            rows={4}
                            value={comment}
                            onChange={setComment}
                        />
                        {loading ?
                            <div className="loading"><CircularProgress/></div>
                            :
                            <Button variant="outlined" className="review-submit-btn" onClick={review}>{alreadySubmitted?"修改評分":"提交評分"}</Button>
                        }
                    </div>
                }
            </Dialog>
        )
    }
}
