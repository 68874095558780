import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import {Container} from "@mui/material";
import LoginAvatar from "./component/login/loginAvatar";
import SearchBox from "./component/searchbox";
import Logo from "./component/logo";
import Story from "./component/story";
import Footer from "./component/footer";
import {getApi} from "./util/api";

import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent, setUserId, setUserProperties} from "firebase/analytics";
import {logScreenView} from "./util/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDK3YXfL12dbMY3xWK5mkfYnERx7cNfP8o",
    authDomain: "gogokiddy.firebaseapp.com",
    projectId: "gogokiddy",
    storageBucket: "gogokiddy.appspot.com",
    messagingSenderId: "933290940594",
    appId: "1:933290940594:web:a77a1814392a289b0a91ab",
    measurementId: "G-GZ4WQ31DRT"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class GoGoKiddy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginResult: null,
            openLogin: false,
            locationTags: [],
        };
    }

    componentDidMount() {
        logScreenView(analytics, "home");
        getApi('api/poi/locationTags', result => {
            if (result.data) {
                this.setState({locationTags: result.data});
            }
        }, err => {}, {"timestamp":1674798470129,"data":[{"id":"HK_Kowloon","name":"九龍"},{"id":"HK_Tsim Sha Tsui","name":"尖沙咀"},{"id":"HK_New Territories","name":"新界"},{"id":"HK_Sha Tin","name":"沙田"},{"id":"HK_Hong Kong Island","name":"香港島"},{"id":"HK_Taikoo Shing","name":"太古城"},{"id":"HK_Sai Kung","name":"Sai Kung"}]});
    }

    render() {
        const {loginResult, openLogin, locationTags} = this.state;
        const setLoginResult = (loginResult) => {
            this.setState({
                loginResult: loginResult
            });
            setUserId(analytics, loginResult.id);
            setUserProperties(analytics, {
                numberOfChild: loginResult.numberOfChild,
                childAge0: loginResult.childAge0,
                childAge1: loginResult.childAge1,
                childAge2: loginResult.childAge2,
                childAge3: loginResult.childAge3,
                childAge4: loginResult.childAge4,
            });
            logEvent(analytics, "setLoginResult");
        }

        const setOpenLogin = (open) => this.setState({openLogin: open});

        return (
            <div>
                <Container>
                    <div className="login-avatar">
                        <LoginAvatar loginResult={loginResult} setLoginResult={setLoginResult} openLogin={openLogin} setOpenLogin={setOpenLogin} locationTags={locationTags} analytics={analytics}/>
                    </div>
                    <div className="content">
                        <div className="search-content">
                            <Logo/>
                            <SearchBox loginResult={loginResult} setOpenLogin={setOpenLogin} locationTags={locationTags} analytics={analytics}/>
                        </div>
                        <div className="story-content">
                            <Story/>
                        </div>
                    </div>
                </Container>
                <Footer analytics={analytics}/>
            </div>
        );
    }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<GoGoKiddy />);
