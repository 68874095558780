import React from 'react';
import {
    Alert,
    Breadcrumbs,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {postApi} from "../../util/api";
import {focusByIdOnEnter, onEnter} from "../../util/input";
import DialogTitleWithClose from "../dialogTitleWithClose";
import {logEvent} from "firebase/analytics";

class RegisterEmailStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessage: null,

            email: null,
            emailTemp: null,
        }
    }

    render() {
        const { next, analytics } = this.props;
        const { errorMessage, loading, email, emailTemp } = this.state;

        const setError = (error) => this.setState({
            loading: false,
            errorMessage: error
        });

        const requestEmailVerifyButtonUp = (event) => {
            if (event.key === 'Enter') {
                requestEmailVerify();
            }
        }

        const emailUpdate = (event) => this.setState({emailTemp: event.target.value});

        const requestEmailVerify = () => {
            logEvent(analytics, "register.email");
            const email = emailTemp;
            this.setState({
                loading: true,
                errorMessage: null,
                email: null
            });
            postApi("api/user/email/verify/request", {
                email: email
            }, result => {
                if (result.error) {
                    setError(result.error.message);
                }
                else {
                    this.setState({
                        loading: false,
                        email: email,
                        errorMessage: null
                    });
                }
            }, err => {
                setError(err);
            }, {"timestamp":1674148574792});
        }

        const verifyEmail = (event) => {
            const otp = event.target.value;
            if (otp.length>=7) {
                this.setState({
                    loading: true,
                    errorMessage: null
                });
                postApi("api/user/email/verify/check", {
                    email: email,
                    emailVerification: otp
                }, result => {
                    if (result.error) {
                        setError(result.error.message);
                    }
                    else {
                        next(email, otp);
                    }
                }, err => {
                    setError(err);
                }, {"timestamp":1674148689008});
            }
        }

        return (
            <div className="register-container">
                <div className="register-tab">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">驗証電郵地址</Typography>
                    </Breadcrumbs>
                </div>
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : <div/>}
                <div className="register-row">
                    <TextField id="standard-basic-email" className="register-field-l" fullWidth={true} label="電郵地址" required onKeyDown={requestEmailVerifyButtonUp} onChange={emailUpdate} inputProps={{
                        enterKeyHint: "go"
                    }}/>
                </div>
                <div className="register-row">
                {email ?
                        <TextField id="standard-basic-otp" className="register-field-s" label="驗證碼" required onChange={verifyEmail}/>
                        :
                        <Button variant="outlined" className="register-btn" onClick={requestEmailVerify}>獲取驗證碼</Button>
                }
                {loading ?
                    <div className="loading"><CircularProgress /></div>
                    : <div/>
                }
                </div>
            </div>
        )
    }
}

class RegisterProfileStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            loading: null,

            password: "",
            passwordError: false,
            passwordErrorHint: null,

            confirmPassword: "",
            confirmPasswordError: false,
            confirmPasswordErrorHint: null,

            name: "",
            nameError: false,
            nameErrorHint: null,

            numberOfChild: null,
            childAge0: false,
            childAge1: false,
            childAge2: false,
            childAge3: false,
            childAge4: false,
        };
    }

    render() {
        const { next, back, email, otp } = this.props;
        const { errorMessage, loading,
            password, passwordError, passwordErrorHint,
            confirmPassword, confirmPasswordError, confirmPasswordErrorHint,
            name, nameError, nameErrorHint,
            numberOfChild, childAge0, childAge1, childAge2, childAge3, childAge4,
        } = this.state;

        const updatePassword = (event) => this.setState({password: event.target.value});
        const passwordNext = (event) => focusByIdOnEnter(event, "standard-basic-password-confirm");
        const updateConfirmPassword = (event) => this.setState({confirmPassword: event.target.value});
        const confirmPasswordNext = (event) => focusByIdOnEnter(event, "standard-basic-name");
        const updateName = (event) => this.setState({name: event.target.value});
        const nameNext = (event) => onEnter(event, ()=>event.target.blur());
        const updateNumberOfChild = (event) => this.setState({numberOfChild: event.target.value});
        const updateChildAge0 = (event) => this.setState({childAge0: event.target.checked});
        const updateChildAge1 = (event) => this.setState({childAge1: event.target.checked});
        const updateChildAge2 = (event) => this.setState({childAge2: event.target.checked});
        const updateChildAge3 = (event) => this.setState({childAge3: event.target.checked});
        const updateChildAge4 = (event) => this.setState({childAge4: event.target.checked});

        const setError = (error) => this.setState({
            loading: false,
            errorMessage: error
        });

        const register = () => {
            this.setState({
                passwordError: false,
                passwordErrorHint: null,
                confirmPasswordError: false,
                confirmPasswordErrorHint: null,
                nameError: false,
                nameErrorHint: null
            });
            if (password==="" || confirmPassword==="" || name==="" || password.length<6 || confirmPassword.length<6) {
                if (password==="") this.setState({
                    passwordError: true,
                    passwordErrorHint: "必填"
                });
                else if (password.length<6) this.setState({
                    passwordError: true,
                    passwordErrorHint: "密碼最少要有6個字符長"
                });
                if (confirmPassword==="") this.setState({
                    confirmPasswordError: true,
                    confirmPasswordErrorHint: "必填"
                });
                else if (confirmPassword.length<6) this.setState({
                    confirmPasswordError: true,
                    confirmPasswordErrorHint: "密碼最少要有6個字符長"
                });
                if (name==="") this.setState({
                    nameError: true,
                    nameErrorHint: "必填"
                });
                return;
            }
            if (password!==confirmPassword) {
                this.setState({
                    confirmPasswordError: true,
                    confirmPasswordErrorHint: "密碼不符"
                });
                return;
            }
            setError(null);
            this.setState({
                loading: true
            });
            postApi("api/user/register", {
                email: email,
                emailVerification: otp,
                password: password,
                name: name,
                numberOfChild: numberOfChild,
                childAge0: childAge0,
                childAge1: childAge1,
                childAge2: childAge2,
                childAge3: childAge3,
                childAge4: childAge4,
            }, result => {
                if (result.error) {
                    setError(result.error.message);
                }
                else {
                    next(result.data);
                }
            }, err => {
                setError(err);
            }, {"timestamp":1674148747990,"data":{"accessToken":"b8fad3f9-1b37-4b40-87dd-f06ec23f0a5e","user":{"id":"63adc7b06ac3425eb22fe363","email":"anita1@email.com","name":"Anita 1","filename":"NFRewards.png","contentType":"image/png","fileSize":4693,"url":"api/assets/upp/63bc4b897bf89ee93323eed3/NFRewards.png"},"numberOfChild":"1","childAge0":false,"childAge1":true,"childAge2":false,"status":"active"}});
        }

        return (
            <div className="register-container">
                <div className="register-tab">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" onClick={back}>
                            驗証電郵地址
                        </Link>
                        <Typography color="text.primary">填寫個人資料</Typography>
                    </Breadcrumbs>
                </div>
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : <div/>}
                <div className="register-row">
                    <TextField id="standard-basic-email" className="register-field-l" label="電郵地址" defaultValue={email} disabled/>
                </div>
                <div className="register-row">
                    <TextField id="standard-basic-password" className="register-field-m" label="密碼" type="password" required onChange={updatePassword} onKeyDown={passwordNext} error={passwordError} helperText={passwordErrorHint} inputProps={{
                        enterKeyHint: "next"
                    }}/>
                    <TextField id="standard-basic-password-confirm" className="register-field-m" label="確認" type="password" required onChange={updateConfirmPassword} onKeyDown={confirmPasswordNext} error={confirmPasswordError} helperText={confirmPasswordErrorHint} inputProps={{
                        enterKeyHint: "next"
                    }}/>
                </div>
                <Divider/>
                <TextField id="standard-basic-name" className="register-field-full" label="名稱" required onChange={updateName} onKeyDown={nameNext} error={nameError} helperText={nameErrorHint} inputProps={{
                    enterKeyHint: "next"
                }}/>
                <FormControl className="register-field-full">
                    <InputLabel id="select-number-of-child-label">你有幾多個仔女?</InputLabel>
                    <Select
                        labelId="select-number-of-child-label"
                        id="select-number-of-child"
                        label="你有幾多個仔女?"
                        value={numberOfChild}
                        onChange={updateNumberOfChild}
                    >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4OrMore">4+</MenuItem>
                    </Select>
                </FormControl>
                <div className="register-checkbox">
                    <p>你有無以下年齡既仔女：</p>
                    <FormControlLabel control={<Checkbox size="small" onChange={updateChildAge0}/>} label="半歲以下" />
                    <FormControlLabel control={<Checkbox size="small" onChange={updateChildAge1}/>} label="半歲至兩歲" />
                    <FormControlLabel control={<Checkbox size="small" onChange={updateChildAge2}/>} label="兩歲至五歲" />
                    <FormControlLabel control={<Checkbox size="small" onChange={updateChildAge3}/>} label="五歲至十歲" />
                    <FormControlLabel control={<Checkbox size="small" onChange={updateChildAge4}/>} label="十歲以上" />
                </div>
                <Divider/>
                {loading ?
                    <div className="loading"><CircularProgress/></div>
                    :
                    <Button variant="outlined" className="register-btn" onClick={register}>註冊</Button>
                }
            </div>
        );
    }
}

export default class RegisterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            email: null,
            otp: null,
        };
    }

    render() {
        const { handleClose, open, handleOpenLogin, setLoginResult, analytics } = this.props;
        const { step, email, otp } = this.state;

        const registerEmailNext = (email, otp) => {
            this.setState({
                email: email,
                otp: otp,
                step: 2,
            });
            logEvent(analytics, "register.otp");
        }
        const registerProfileBack = () => {
            this.setState({
                step: 1,
            });
        };
        const registerProfileNext = (data) => {
            logEvent(analytics, "register.submit");
            setLoginResult(data.user);
            handleClose();
        };

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitleWithClose title="新用戶註冊" handleClose={handleClose}/>
                <div className="register-container-outer">
                    {step===1 ?
                        <RegisterEmailStep next={registerEmailNext} analytics={analytics}/>
                        :
                        step===2 ?
                            <RegisterProfileStep next={registerProfileNext} back={registerProfileBack} email={email} otp={otp}/>
                            :
                            <div/>
                    }
                    <Button onClick={handleOpenLogin}>已註冊? 立即登入</Button>
                </div>

            </Dialog>
        );
    }
}
