import React from 'react';

export default class Story extends React.Component {
    render() {
        return (
            <div className="story-content-0">
                <p className="story-words-0 story-words">
                    帶小朋友出街食飯，可以好天堂，又可以好地獄。<br/><br/>
                    有無試過去到餐廳先知無BB凳? 仲要全部座位都唔children friendly！入到去啲侍應哥哥見到個小朋友黑晒面？<br/><br/>
                    如果可以出發前已經知道餐廳適唔適合小朋友去，你話幾好？<br/><br/>
                    即刻搜尋你想去嘅餐廳啦！去完記得落返個review呀~
                </p>
                <img src="/story-photo-0.png" className="story-photo-0"/>
            </div>
        );
    }
}
