import {getApi} from "./api";

export const handlePoiUpdateSuccess = (items, poi) => {
    const i = items.find(item => item.id === poi.id);
    i.ratingAverage = poi.ratingAverage;
    i.ratingCount = poi.ratingCount;
    i.ratingSum = poi.ratingSum;
    i.totalReview = poi.totalReview;
    i.facilities = poi.facilities;
}

export const handlePoiLoadOtherShops = (component, items, poiId, setOtherShopLoading) => {
    getApi('api/poi/otherShops?id='+poiId, (result) => {
        if (result.data) {
            result.data.forEach(d => d.isOtherShop = true);
            const index = items.map(i=>i.id).indexOf(poiId)+1;
            const newItems = [...items.slice(0, index), ...result.data, ...items.slice(index)];
            component.setState({
                items: newItems
            });
            setOtherShopLoading(false);
        }}, (error) => {}, {"timestamp":1674231208143,"data":[{"id":"63b5bc2a4760aefb59c7d126","name":"無印良品 太古城店","address":"香港太古城太古城道18號","types":["home_goods_store","store","point_of_interest","establishment"],"ratingAverage":{"overall":0,"food":0,"crowdedness":0,"seats":0},"facilities":{"highChair":{"exist":"yes","yesCount":1,"noCount":0},"childrenMeal":{"exist":"unknown","yesCount":0,"noCount":0}},"website":"http://www.muji.com/hk/","totalReview":1,"createdAt":1672854570733,"lastUpdateAt":1672855085001},{"id":"63b5b6903fa9f727bfb6bcc9","name":"無印良品 新城市廣場店II","address":"香港沙田沙田正街2-8號新城市廣場3期2樓A221號","types":["home_goods_store","store","point_of_interest","establishment"],"ratingAverage":{"overall":4.5,"food":4.5,"crowdedness":4.666666666666667,"seats":4.666666666666667},"facilities":{"highChair":{"exist":"yes","yesCount":6,"noCount":0},"childrenMeal":{"exist":"yes","yesCount":5,"noCount":1}},"website":"https://www.muji.com/hk/","totalReview":6,"createdAt":1672853136206,"lastUpdateAt":1674148354823},{"id":"63c833acd24624eceb95a658","name":"Café&Meal MUJI 海港城店","address":"Shop 415-419, L4, Ocean Centre, Harbour City, Tsim Sha Tsui, 香港","types":["cafe","store","food","point_of_interest","establishment"],"ratingAverage":{},"facilities":{"highChair":{"exist":"unknown","yesCount":0,"noCount":0},"childrenMeal":{"exist":"unknown","yesCount":0,"noCount":0}},"website":"https://www.muji.com/hk/","totalReview":0,"createdAt":1674064812430,"lastUpdateAt":1674064812430}]});
}

export const handlePoiBookmark = (component, items, poiId, bookmarked) => {
    component.setState(prevState => {
        const item = prevState.items.find(i => i.id === poiId);
        item.bookmarked = bookmarked;
        return {
            items: items
        };
    });
}