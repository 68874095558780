import React from "react";
import {FormControlLabel, Switch, Typography} from "@mui/material";

export default class FacilitiesFilter extends React.Component {
    render() {
        const {facilitiesHighChair, facilitiesChildrenMeal,
            handleUpdateFacilitiesHighChair, handleUpdateFacilitiesChildrenMeal} = this.props;

        return (
            <div className="filter-switch-container">
                <Typography variant="subtitle2" color="text.secondary">只顯示：</Typography>
                <div className="filter-switch">
                    <FormControlLabel control={<Switch checked={facilitiesHighChair} onChange={handleUpdateFacilitiesHighChair}/>} label="BB凳/高凳" />
                    <FormControlLabel control={<Switch checked={facilitiesChildrenMeal} onChange={handleUpdateFacilitiesChildrenMeal}/>} label="兒童餐" />
                </div>
            </div>
        )
    }
}