const useDummy = false;
const dummyTimeout = 1000;

export const postApi = (apiPath, postBody, callback, errorCallback, dummy = null, lang="zh", countryCode = "HK") => {
    if (useDummy) setTimeout(() => callback(dummy), dummyTimeout);
    else {
        const apiPathWithLang = apiPath + (apiPath.includes("?") ? "&" : "?") + "lang=" + lang + "&countryCode=" + countryCode;
        fetch(process.env.REACT_APP_API_PREFIX + apiPathWithLang, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(postBody)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    callback(result);
                },
                (err) => {
                    errorCallback(err);
                }
            );
    }
}

export const getApi = (apiPath, callback, errorCallback, dummy = null, lang="zh", countryCode = "HK") => {
    if (useDummy) setTimeout(() => callback(dummy), dummyTimeout);
    else {
        const apiPathWithLang = apiPath + (apiPath.includes("?") ? "&" : "?") + "lang=" + lang + "&countryCode=" + countryCode;
        fetch(process.env.REACT_APP_API_PREFIX + apiPathWithLang, {
            credentials: 'include',
        })
            .then(res => res.json())
            .then(
                (result) => {
                    callback(result);
                },
                (error) => {
                    errorCallback(error);
                }
            );
    }
}

export const uploadFileApi = (apiPath, file, callback, errorCallback, dummy = null, lang="zh", countryCode = "HK") => {
    if (useDummy) setTimeout(() => callback(dummy), dummyTimeout);
    else {
        const apiPathWithLang = apiPath + (apiPath.includes("?") ? "&" : "?") + "lang=" + lang + "&countryCode=" + countryCode;
        const formData = new FormData();
        formData.append("file", file);
        fetch(process.env.REACT_APP_API_PREFIX + apiPathWithLang, {
            method: 'POST',
            credentials: 'include',
            body: formData
        })
            .then(res => res.json())
            .then(
                (result) => {
                    callback(result);
                },
                (err) => {
                    errorCallback(err);
                }
            );
    }
}