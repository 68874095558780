import React from "react";
import {Chip, Container, Dialog, DialogTitle, Link, Typography} from "@mui/material";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DialogTitleWithClose from "./dialogTitleWithClose";
import {logScreenView} from "../util/analytics";

class AboutProducer extends React.Component {
    render() {
        const {handleClose, open} = this.props;

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitleWithClose title="製作人的話" handleClose={handleClose}/>
                <div className="about-producer-container">
                    <Typography>
                        感謝您對GoGoKiddy的支持！<br/><br/>
                        GoGoKiddy係由一個在職媽媽，用BB晚上睡覺嘅時間做的。<br/>如果有咩做得唔好，請多多包涵。<br/><br/>
                        我只係想做一個方便各位家長嘅平台，希望大家喜歡！
                    </Typography>
                </div>
            </Dialog>
        )
    }
}

class FAQ extends React.Component {
    render() {
        const {handleClose, open} = this.props;

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitleWithClose title="常見問題 FAQ" handleClose={handleClose}/>
                <div className="faq-container">
                    <FAQEntry question="Search唔到某間餐廳" answer="我哋係用Google嘅API去Search地方，如果Google上面無呢間餐廳，就會Search唔到。另外，如果要Search某間分店，可以試吓加埋 地區、大廈/商場名（例：麥當勞 新城市廣場）"/>
                    <div className="faq-item">
                        <Typography className="question">
                            收唔到認證電郵
                        </Typography>
                        <Typography className="answer">
                            可以試試睇吓「垃圾郵件」，或者聯絡 <Link href="mailto:admin@gogokiddy.com">admin@gogokiddy.com</Link>
                        </Typography>
                    </div>
                </div>
            </Dialog>
        )
    }
}

class FAQEntry extends React.Component {
    render() {
        const {question, answer} = this.props;

        return (
            <div className="faq-item">
                <Typography className="question">{question}</Typography>
                <Typography className="answer">{answer}</Typography>
            </div>
        )
    }
}

class AboutFeedback extends React.Component {
    render() {
        const {handleClose, open} = this.props;

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitleWithClose title="意見反饋" handleClose={handleClose}/>
                <div className="feedback-container">
                    <Typography>
                        電郵地址 - <Chip icon={<EmailOutlinedIcon />} label="admin@gogokiddy.com" color="secondary" component="a" href="mailto:admin@gogokiddy.com" clickable/>
                    </Typography>
                </div>
            </Dialog>
        )
    }
}

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAboutProducer: false,
            openFeedback: false,
            openFaq: false,
        }
    }

    render() {
        const {analytics} = this.props;
        const {openAboutProducer, openFeedback, openFaq} = this.state;

        const handleOpenAboutProducer = () => {
            logScreenView(analytics, 'aboutProducer');
            this.setState({openAboutProducer: true});
        }
        const handleCloseAboutProducer = () => this.setState({openAboutProducer: false})

        const handleOpenFeedback = () => {
            logScreenView(analytics, 'feedback');
            this.setState({openFeedback: true});
        }
        const handleCloseFeedback = () => this.setState({openFeedback: false})

        const handleOpenFaq = () => {
            logScreenView(analytics, 'faq');
            this.setState({openFaq: true});
        }
        const handleCloseFaq = () => this.setState({openFaq: false})

        return (
            <Container>
                <div className="footer">
                    <img src="gogokiddy-logo.svg" className="logo"/>
                    <Typography variant="body2" color="text.secondary" onClick={handleOpenAboutProducer} className="mouse-pointer">．製作人的話</Typography>
                    <AboutProducer open={openAboutProducer} handleClose={handleCloseAboutProducer}/>
                    <Typography variant="body2" color="text.secondary" onClick={handleOpenFaq} className="mouse-pointer">．常見問題 FAQ</Typography>
                    <FAQ open={openFaq} handleClose={handleCloseFaq}/>
                    <Typography variant="body2" color="text.secondary" onClick={handleOpenFeedback} className="mouse-pointer">．意見反饋</Typography>
                    <AboutFeedback open={openFeedback} handleClose={handleCloseFeedback}/>
                    <Typography variant="body2" color="text.secondary" fontSize="small" className="terms">© 2023 GoGoKiddy 版權所有</Typography>
                </div>
            </Container>
        );
    }
}