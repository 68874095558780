import React from 'react';
import {uploadFileApi} from "../../util/api";
import {Alert, Avatar} from "@mui/material";
import {getSrc, handleCompressedUpload} from "../../util/file";

export default class UploadAvatar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
        }
        this.filInputRef = React.createRef();
    }

    focusFileInput = () => {
        this.filInputRef.current.click();
    }

    setErrorMessage = (errorMessage) => this.setState({errorMessage: errorMessage});

    handleImagePreview = (e) => {
        let image_as_files = e.target.files[0];

        handleCompressedUpload(image_as_files, compress_result => {
            uploadFileApi('api/user/upload/profilePic', compress_result, result => {
                if (result.error)
                    this.setErrorMessage(result.error.message);
                else
                    this.props.setLoginResult(result.data);
            }, err => {
                this.setErrorMessage(err);
            }, {"timestamp":1674147689494,"data":{"id":"63adc7b06ac3425eb22fe363","email":"anita1@email.com","name":"Anita 1","profilePic":{"id":"63bc4b897bf89ee93323eed3","filename":"NFRewards.png","contentType":"image/png","fileSize":4693,"url":"api/assets/upp/63bc4b897bf89ee93323eed3/NFRewards.png"},"numberOfChild":"1","childAge0":false,"childAge1":true,"childAge2":false,"status":"active"}});
        });
    }

    render() {
        const {loginResult} = this.props;
        const {errorMessage} = this.state;
        return (
            <div className="me-avatar-container">
                <Avatar className="me-avatar" src={getSrc(loginResult.profilePic)} sx={{ width: 80, height: 80 }} onClick={this.focusFileInput}/>
                {errorMessage ? (<Alert severity="error">{errorMessage}</Alert>) : (<div/>)}
                <input
                    type="file"
                    accept="image/*"
                    ref={this.filInputRef}
                    onChange={this.handleImagePreview}
                    style={{visibility: 'hidden'}}
                />
            </div>
        );
    }
}