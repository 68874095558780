import React from 'react';
import {
    Alert,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {postApi} from "../../util/api";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import UploadAvatar from "./uploadAvatar";
import DialogTitleWithClose from "../dialogTitleWithClose";

const initialUpdateProfileState = (props) => {
    return {
        loading: false,
        updated: false,
        errorMessage: null,

        name: props.loginResult.name,
        nameError: false,
        nameErrorHint: null,

        numberOfChild: props.loginResult.numberOfChild,
        childAge0: props.loginResult.childAge0,
        childAge1: props.loginResult.childAge1,
        childAge2: props.loginResult.childAge2,
        childAge3: props.loginResult.childAge3,
        childAge4: props.loginResult.childAge4,
    }
};

class UpdateProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialUpdateProfileState(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldOpen = prevProps.open;
        const newOpen = this.props.open;
        if (oldOpen && !newOpen) {
            this.setState(initialUpdateProfileState(this.props));
        }
    }

    render() {
        const {open, handleClose, setLoginResult} = this.props;
        const { errorMessage, updated, loading,
            name, nameError, nameErrorHint,
            numberOfChild, childAge0, childAge1, childAge2, childAge3, childAge4,
        } = this.state;

        const updateName = (event) => this.setState({name: event.target.value});
        const updateNumberOfChild = (event) => this.setState({numberOfChild: event.target.value});
        const updateChildAge0 = (event) => this.setState({childAge0: event.target.checked});
        const updateChildAge1 = (event) => this.setState({childAge1: event.target.checked});
        const updateChildAge2 = (event) => this.setState({childAge2: event.target.checked});
        const updateChildAge3 = (event) => this.setState({childAge3: event.target.checked});
        const updateChildAge4 = (event) => this.setState({childAge4: event.target.checked});

        const setError = (error) => this.setState({
            loading: false,
            errorMessage: error
        });

        const submit = () => {
            this.setState({
                nameError: false,
                nameErrorHint: null
            });
            if (name==="") {
                if (name==="") this.setState({
                    nameError: true,
                    nameErrorHint: "必填"
                });
                return;
            }
            this.setState({
                loading: true,
            });
            postApi('api/user/update/profile', {
                name: name,
                numberOfChild: numberOfChild,
                childAge0: childAge0,
                childAge1: childAge1,
                childAge2: childAge2,
                childAge3: childAge3,
                childAge4: childAge4,
            }, result => {
                if (result.error) {
                    setError(result.error.message);
                }
                else {
                    setLoginResult(result.data);
                    this.setState({
                        loading: false,
                        updated: true,
                    })
                }
            }, err => {
                setError(err);
            }, {"timestamp":1674147689494,"data":{"id":"63adc7b06ac3425eb22fe363","email":"anita1@email.com","name":"Anita 1","profilePic":{"id":"63bc4b897bf89ee93323eed3","filename":"NFRewards.png","contentType":"image/png","fileSize":4693,"url":"api/assets/upp/63bc4b897bf89ee93323eed3/NFRewards.png"},"numberOfChild":"1","childAge0":false,"childAge1":true,"childAge2":false,"status":"active"}});
        }

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitleWithClose title="更改資料" handleClose={handleClose}/>
                {
                    updated ?
                        <div className="me-container">
                            <Typography className="me-success-message">
                                成功更改資料
                            </Typography>
                        </div> :
                        <div className="me-container">
                            {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : <div/>}
                            <TextField id="standard-basic-name" fullWidth={true} label="名稱" required onChange={updateName} error={nameError} helperText={nameErrorHint} defaultValue={name}/>
                            <FormControl className="register-field-full" fullWidth={true}>
                                <InputLabel id="select-number-of-child-label">你有幾多個仔女?</InputLabel>
                                <Select
                                    labelId="select-number-of-child-label"
                                    id="select-number-of-child"
                                    label="你有幾多個仔女?"
                                    value={numberOfChild}
                                    onChange={updateNumberOfChild}
                                >
                                    <MenuItem value="0">0</MenuItem>
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                    <MenuItem value="4OrMore">4+</MenuItem>
                                </Select>
                            </FormControl>
                            <div className="register-checkbox">
                                <p>你有無以下年齡既仔女：</p>
                                <ChildAgeInput onChange={updateChildAge0} value={childAge0} label="半歲以下"/>
                                <ChildAgeInput onChange={updateChildAge1} value={childAge1} label="半歲至兩歲"/>
                                <ChildAgeInput onChange={updateChildAge2} value={childAge2} label="兩歲至五歲"/>
                                <ChildAgeInput onChange={updateChildAge3} value={childAge3} label="五歲至十歲"/>
                                <ChildAgeInput onChange={updateChildAge4} value={childAge4} label="十歲以上"/>
                            </div>

                            {loading ?
                                <div className="loading"><CircularProgress/></div> :
                                <Button variant="outlined" fullWidth={true} onClick={submit} color="primary">更改資料</Button>
                            }
                        </div>
                }
            </Dialog>
        );
    }
}

class ChildAgeInput extends React.Component {
    render() {
        const {onChange,value,label} = this.props;

        if (value)
            return (<FormControlLabel control={<Checkbox size="small" onChange={onChange} defaultChecked/>} label={label} />);
        else
            return (<FormControlLabel control={<Checkbox size="small" onChange={onChange}/>} label={label} />);
    }
}

class ProfileDisplay extends React.Component {

    render() {
        const {loginResult} = this.props;
        return (
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className="me-header">電郵地址</TableCell>
                            <TableCell>{loginResult.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="me-header">名稱</TableCell>
                            <TableCell>{loginResult.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="me-header">仔女總數</TableCell>
                            <TableCell>{loginResult.numberOfChild ? loginResult.numberOfChild : "/"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="me-header">
                                仔女年齡
                            </TableCell>
                            <TableCell>
                                <div className="me-child-age">
                                    <ChildAgeDisplayValue label="半歲以下" value={loginResult.childAge0}/>
                                    <ChildAgeDisplayValue label="半歲至兩歲" value={loginResult.childAge1}/>
                                    <ChildAgeDisplayValue label="兩歲至五歲" value={loginResult.childAge2}/>
                                    <ChildAgeDisplayValue label="五歲至十歲" value={loginResult.childAge3}/>
                                    <ChildAgeDisplayValue label="十歲以上" value={loginResult.childAge4}/>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

class ChildAgeDisplayValue extends React.Component {
    render() {
        const {label, value} = this.props;

        if (value!==undefined && value!==null && value)
            return <Chip icon={<CheckOutlinedIcon/>} label={label} color="primary"/>;
        else
            return <Chip icon={<CloseOutlinedIcon/>} label={label} variant="outlined"/>;
    }
}

export default class MeContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openUpdateProfile: false,
        }
    }

    render() {
        const { handleClose, open,
            loginResult, setLoginResult } = this.props;
        const {openUpdateProfile} = this.state;

        const handleOpenUpdateProfile = () => this.setState({openUpdateProfile: true});
        const handleCloseUpdateProfile = () => this.setState({openUpdateProfile: false});

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitleWithClose title="用戶資料" handleClose={handleClose}/>
                <div className="me-container">
                    <div>
                        <UploadAvatar loginResult={loginResult} setLoginResult={setLoginResult}/>
                    </div>

                    <ProfileDisplay loginResult={loginResult}/>

                    <Button variant="outlined" onClick={handleOpenUpdateProfile} color="primary">更改資料</Button>
                    <UpdateProfileContainer open={openUpdateProfile} handleClose={handleCloseUpdateProfile} loginResult={loginResult} setLoginResult={setLoginResult}/>
                </div>
            </Dialog>
        );
    }
}