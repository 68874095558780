import React from 'react';
import {Alert, Button, CircularProgress, Dialog, TextField} from "@mui/material";
import {postApi} from "../../util/api";
import {focusByIdOnEnter, onEnter} from "../../util/input";
import DialogTitleWithClose from "../dialogTitleWithClose";

export default class LoginContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            loading: false,
            email: "",
            emailError: false,
            emailErrorHint: null,
            password: "",
            passwordError: false,
            passwordErrorHint: null,
        };
    }

    render() {
        const { handleClose, open, handleOpenRegister, handleOpenForgetPassword, setLoginResult } = this.props;
        const { errorMessage, loading, email, emailError, emailErrorHint, password, passwordError, passwordErrorHint } = this.state;

        const updateEmail = (event) => this.setState({email: event.target.value});
        const emailNext = (event) => focusByIdOnEnter(event, "standard-basic-password");
        const updatePassword = (event) => this.setState({password: event.target.value});
        const passwordNext = (event) => onEnter(event, login);
        const setErrorMessage = (errorMessage) => this.setState({errorMessage: errorMessage});

        const login = () => {
            this.setState({
                emailError: false,
                emailErrorHint: null,
                passwordError: false,
                passwordErrorHint: null,
            });
            if (email==="" || password==="") {
                if (email==="") this.setState({
                    emailError: true,
                    emailErrorHint: "必填"
                });
                if (password==="") this.setState({
                    passwordError: true,
                    passwordErrorHint: "必填"
                });
                return;
            }
            setErrorMessage(null);
            this.setState({
                loading: true
            });
            postApi("api/user/login", {
                email: email,
                password: password,
            }, (result) => {
                    if (result.data) {
                        setErrorMessage(null);
                        setLoginResult(result.data.user);
                    }
                    else {
                        setErrorMessage(result.error.message);
                    }
                    this.setState({
                        loading: false
                    });
                },(err) => {
                setErrorMessage("請稍後再嘗試");
                this.setState({
                    loading: false
                });
            }, {"timestamp":1674147618526,"data":{"accessToken":"37af1537-9ceb-4ec5-b711-cb444f371f16","user":{"id":"63adc7b06ac3425eb22fe363","email":"anita1@email.com","name":"Anita 1","profilePic":{"id":"63bc4b897bf89ee93323eed3","filename":"NFRewards.png","contentType":"image/png","fileSize":4693,"url":"api/assets/upp/63bc4b897bf89ee93323eed3/NFRewards.png"},"numberOfChild":"1","childAge0":false,"childAge1":true,"childAge2":false,"status":"active"}}});
        }

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitleWithClose title="登入" handleClose={handleClose}/>
                {errorMessage ? (<Alert severity="error">{errorMessage}</Alert>) : (<div/>)}
                <div className="login-container">
                    <TextField id="standard-basic-email" fullWidth={true} label="電郵地址" required onChange={updateEmail} onKeyDown={emailNext} error={emailError} helperText={emailErrorHint} inputProps={{
                        enterKeyHint: "next"
                    }}/>
                    <TextField id="standard-basic-password" fullWidth={true} label="密碼" type="password" required onChange={updatePassword} onKeyDown={passwordNext} error={passwordError} helperText={passwordErrorHint} inputProps={{
                        enterKeyHint: "go"
                    }}/>
                    {loading ?
                        <div className="loading"><CircularProgress/></div>
                        :
                        <Button variant="outlined" fullWidth={true} onClick={login}>登入</Button>
                    }
                    <Button color="info" fullWidth={true} onClick={handleOpenForgetPassword}>忘記密碼</Button>
                    <Button fullWidth={true} onClick={handleOpenRegister}>未有戶口? 立即註冊</Button>
                </div>
            </Dialog>
        );
    }
}
