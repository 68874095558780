import React from 'react';
import {Alert, AlertTitle, Button, CircularProgress, Popover, Stack, TextField, Typography} from "@mui/material";
import SearchResultEntry from "./searchResult";
import {getApi} from "../util/api";
import CloseButton from "./closeButton";
import {handlePoiBookmark, handlePoiLoadOtherShops, handlePoiUpdateSuccess} from "../util/poi";
import ExploreContainer from "./explore";
import {logEvent} from "firebase/analytics";
import {logScreenView} from "../util/analytics";

export default class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            loading: false,
            items: [],
            anchorEl: null,
            openExplore: false,
        };
    }

    render() {
        const { loginResult, setOpenLogin, locationTags, analytics } = this.props;
        const { errorMessage, loading, items, anchorEl, openExplore} = this.state;

        const handleClose = () => {
            this.setState({anchorEl: null});
        };
        const open = Boolean(anchorEl);
        const id = open ? 'search-result' : undefined;

        const search = (event) => {
            if (event.key === 'Enter') {
                const target = event.currentTarget;
                logEvent(analytics, 'search');
                this.setState({
                    loading: true,
                    anchorEl: target
                });
                getApi("api/poi/search?searchText=" + encodeURIComponent(event.target.value), result => {
                    if (result.error) {
                        this.setState({
                            loading: false,
                            errorMessage: result.error,
                            item: null,
                            anchorEl: target
                        });
                    }
                    else {
                        result.data.forEach(d => d.isOtherShop = false);
                        this.setState({
                            loading: false,
                            items: result.data,
                            errorMessage: null,
                            anchorEl: target
                        });
                    }
                }, err => {
                    this.setState({
                        loading: false,
                        errorMessage: err,
                        item: null,
                        anchorEl: target
                    });
                }, {"timestamp":1674148527606,"data":[{"id":"63b5b6903fa9f727bfb6bcc9","name":"無印良品 新城市廣場店","address":"香港沙田沙田正街2-8號新城市廣場3期2樓A221號","types":["home_goods_store","store","point_of_interest","establishment"],"ratingAverage":{"overall":4.5,"food":4.5,"crowdedness":4.666666666666667,"seats":4.666666666666667},"facilities":{"highChair":{"exist":"yes","yesCount":6,"noCount":0},"childrenMeal":{"exist":"yes","yesCount":5,"noCount":1}},"website":"https://www.muji.com/hk/","totalReview":6,"createdAt":1672853136206,"lastUpdateAt":1674148354823, "bookmarked": true},{"id":"63b5b6903fa9f727bfb6bcc9asd","name":"無印良品 新城市廣場店","address":"香港沙田沙田正街2-8號新城市廣場3期2樓A221號","types":["home_goods_store","store","point_of_interest","establishment"],"ratingAverage":{"overall":4.5,"food":4.5,"crowdedness":4.666666666666667,"seats":4.666666666666667},"facilities":{"highChair":{"exist":"no","yesCount":1,"noCount":4},"childrenMeal":{"exist":"unknown","yesCount":1,"noCount":1}},"website":"https://www.muji.com/hk/","totalReview":0,"createdAt":1672853136206,"lastUpdateAt":1674148354823}]});
            }
        }

        const handleUpdateSuccess = (poi) => {
            return handlePoiUpdateSuccess(items, poi);
        }

        const handleLoadOtherShops = (poiId, setOtherShopLoading) => {
            logEvent(analytics, 'loadOtherShops', {poiId});
            return handlePoiLoadOtherShops(this, items, poiId, setOtherShopLoading);
        }

        const handleBookmark = (poiId, bookmarked) => {
            if (bookmarked) logEvent(analytics, 'bookmark', {poiId});
            else logEvent(analytics, 'unbookmark', {poiId});
            return handlePoiBookmark(this, items, poiId, bookmarked);
        }

        const handleOpenExplore = () => {
            logScreenView(analytics, 'explore');
            this.setState({openExplore: true});
        }
        const handleCloseExplore = () => this.setState({openExplore: false});

        return (
            <div>
                <TextField className="search-box" id="outlined-basic-search" variant="outlined"
                           aria-describedby={id}
                           label="搜尋"
                           helperText="搜尋餐廳名、地區、大廈/商場名... 例：麥當勞 新城市廣場"
                           onKeyDown={search}
                           inputProps={{
                               enterKeyHint: "search"
                           }}
                />
                <div className="explore-btn-container">
                    <Typography variant="body1" color="text.secondary">心目中未有餐廳？試試</Typography>
                    <Button color="info" onClick={handleOpenExplore}>進階搜尋</Button>
                </div>
                <ExploreContainer open={openExplore} handleClose={handleCloseExplore} locationTags={locationTags} analytics={analytics}/>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="search-box">
                        {loading ?
                            <div className="loading"><CircularProgress /></div>
                            : errorMessage ?
                                <div className="error">
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        系統暫時有啲問題，<strong>請稍後再嘗試</strong>
                                    </Alert>
                                </div>
                                :
                                <div>
                                    <div className="dialog-close">
                                        <div/>
                                        <CloseButton handleClose={handleClose}/>
                                    </div>
                                    <Stack>
                                        {items.map(item => (
                                            <SearchResultEntry item={item} key={item.id} loginResult={loginResult} setOpenLogin={setOpenLogin} handleUpdateSuccess={handleUpdateSuccess} handleLoadOtherShops={handleLoadOtherShops} handleBookmarkFunc={handleBookmark} analytics={analytics}/>
                                        ))}
                                    </Stack>
                                </div>
                        }
                    </div>
                </Popover>
            </div>
        );
    }
}
