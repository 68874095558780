import React from "react";
import {Avatar, IconButton, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from '@mui/icons-material/Edit';
import {getSrc} from "../util/file";
import {displayTimestamp} from "../util/date";
import RatingDisplayBlock from "./ratingDisplay";
import ReviewContainer from "./review";

class FacilityDisplay extends React.Component {
    render() {
        const {name, facility} = this.props;
        return (
            <div className="review-list-facility">
                <Typography variant="body1" color="text.secondary">
                    {name}:
                </Typography>
                {facility && facility === "yes" ?
                    <CheckCircleIcon className="facility-yes-icon"/> :
                    facility && facility === "no" ?
                        <CancelIcon className="facility-no-icon"/> :
                        ( <span>&nbsp;/</span> )
                }
            </div>
        );
    }
}

export default class ReviewListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openReview: false,
            initReview: false,
        };
    }

    render() {
        const {review, type, handleUpdateSuccess, analytics} = this.props;
        const {openReview, initReview} = this.state;

        const handleOpenReview = () => {
            this.setState({
                openReview: true,
                initReview: true,
            });
        }
        const handleCloseReview = () => this.setState({openReview: false});

        return (
            <div className="review-list-item-container">
                <div className="item-section avatar">
                    {type === "poi" ?
                        <Avatar src={getSrc(review.user.profilePic)}/> : <div/>
                    }
                    {type === "poi" ?
                        <div className="review-list-name">
                            <Typography variant="subtitle2" color="text.secondary" noWrap={true}>
                                {review.user.name}
                            </Typography>
                        </div> : <div/>
                    }
                    {type === "user" ?
                        <div className="review-list-name">
                            <Typography variant="subtitle1" color="text.secondary">
                                {review.poi.name}
                            </Typography>
                            <IconButton aria-label="edit" onClick={handleOpenReview}>
                                <EditIcon />
                            </IconButton>
                            <ReviewContainer item={{id: review.poiId, name: review.poi.name}} open={openReview} handleClose={handleCloseReview} init={initReview} handleUpdateSuccess={handleUpdateSuccess} analytics={analytics}/>
                        </div> : <div/>
                    }
                    <Typography variant="caption" color="text.secondary" align='center'>
                        {displayTimestamp(review.createdAt)}
                    </Typography>
                    {
                        review.lastUpdateAt > review.createdAt ?
                            <div className="review-list-edited">
                                <Typography variant="caption" color="text.secondary" align='center'>
                                    (更新)
                                </Typography>
                                <Typography variant="caption" color="text.secondary" align='center'>
                                    {displayTimestamp(review.lastUpdateAt)}
                                </Typography>
                            </div>: <div/>
                    }
                </div>

                <div>
                    <RatingDisplayBlock rating={review.rating}/>
                    <div className="item-section">
                        <Typography variant="subtitle1">
                            設施:
                        </Typography>
                        <div className="item-section-list">
                            <FacilityDisplay name="BB凳/高凳" facility={review.facilities.highChair}/>
                            <FacilityDisplay name="兒童餐" facility={review.facilities.childrenMeal}/>
                        </div>
                    </div>

                    <div className="item-section">
                        <Typography variant="subtitle1">
                            評語:
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {review.comment ? review.comment : "/"}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}
