import React from "react";
import {Autocomplete, IconButton, TextField} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

export default class LocationFilter extends React.Component {
    render() {
        const {
            locationTags, locationTagsSelected, handleChangeLocationTag, handleLocationTagKeyUp, handleLocationTagFilter, displayBtn
        } = this.props;

        return (
            <div className="filter-autocomplete">
                <Autocomplete
                    size="small"
                    multiple
                    options={locationTags}
                    defaultValue={locationTagsSelected}
                    getOptionLabel={(option) => option.name}
                    onChange={handleChangeLocationTag}
                    onKeyUp={handleLocationTagKeyUp}
                    fullWidth={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="地區"
                        />
                    )}
                />
                {displayBtn ?
                    <IconButton aria-label="filter" onClick={handleLocationTagFilter}>
                        <FilterListIcon />
                    </IconButton> : <div/>}
            </div>
        )
    }
}