import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";

export default class CloseButton extends React.Component {
    render() {
        const {handleClose} = this.props;

        return (
            <IconButton aria-label="delete" onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        )
    }
}