import React from "react";
import Sorting from "./sorting";
import FacilitiesFilter from "./facilities";

export default class ReviewFilterContainer extends React.Component {
    render() {
        const {
            orderBy, handleChangeOrder,
            facilitiesHighChair, facilitiesChildrenMeal, handleUpdateFacilitiesHighChair, handleUpdateFacilitiesChildrenMeal
        } = this.props;

        return (
            <div className="filter-container">
                <Sorting orderBy={orderBy} handleChangeOrder={handleChangeOrder} options={[
                    {id: "latestCreated", name: "最新至最舊"},
                    {id: "earliestCreated", name: "最舊至最新"},
                    {id: "highestOverall", name: "整體評分-高至低"},
                    {id: "lowestOverall", name: "整體評分-低至高"},
                    {id: "highestFood", name: "食物合適度-高至低"},
                    {id: "lowestFood", name: "食物合適度-低至高"},
                    {id: "highestCrowdedness", name: "座位寬敞度-高至低"},
                    {id: "lowestCrowdedness", name: "座位寬敞度-低至高"},
                    {id: "highestSeats", name: "座位舒適度-高至低"},
                    {id: "lowestSeats", name: "座位舒適度-低至高"},
                ]}/>
                <FacilitiesFilter facilitiesHighChair={facilitiesHighChair} facilitiesChildrenMeal={facilitiesChildrenMeal} handleUpdateFacilitiesHighChair={handleUpdateFacilitiesHighChair} handleUpdateFacilitiesChildrenMeal={handleUpdateFacilitiesChildrenMeal}/>
            </div>
        )
    }
}