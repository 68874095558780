import Compressor from 'compressorjs';

export const getSrc = (fileMeta) => {
    if (fileMeta === undefined || fileMeta === null) return "";
    return process.env.REACT_APP_API_PREFIX+fileMeta.url
}

export const handleCompressedUpload = (image, callback) => {
    new Compressor(image, {
        quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
            callback(compressedResult)
        },
    });
};