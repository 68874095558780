import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export default class Sorting extends React.Component {
    render() {
        const {orderBy, handleChangeOrder, options} = this.props;

        return (
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="order-label">排序</InputLabel>
                <Select
                    labelId="order-label"
                    value={orderBy}
                    label="排序"
                    onChange={handleChangeOrder}
                >
                    {options.map(option =>
                        <MenuItem value={option.id}>{option.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
        )
    }
}