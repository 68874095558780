
export const parseOrderBy = (orderBy) => {
    switch (orderBy) {
        case "earliestCreated" :
            return "&orderBy=createdAt&orderByAsc=true";
        case "highestOverall":
            return "&orderBy=rating.overall&orderByAsc=false";
        case "lowestOverall":
            return "&orderBy=rating.overall&orderByAsc=true";
        case "highestFood":
            return "&orderBy=rating.food&orderByAsc=false";
        case "lowestFood":
            return "&orderBy=rating.food&orderByAsc=true";
        case "highestCrowdedness":
            return "&orderBy=rating.crowdedness&orderByAsc=false";
        case "lowestCrowdedness":
            return "&orderBy=rating.crowdedness&orderByAsc=true";
        case "highestSeats":
            return "&orderBy=rating.seats&orderByAsc=false";
        case "lowestSeats":
            return "&orderBy=rating.seats&orderByAsc=true";
        default:
            return "&orderBy=createdAt&orderByAsc=false";
    }
}

export const parseFacilities = (facilitiesHighChair, facilitiesChildrenMeal) => {
    if (!facilitiesHighChair && !facilitiesChildrenMeal)
        return "";
    if (facilitiesHighChair && facilitiesChildrenMeal)
        return "&facilitiesHighChair=true&facilitiesChildrenMeal=true";
    if (facilitiesHighChair)
        return "&facilitiesHighChair=true";
    if (facilitiesChildrenMeal)
        return "&facilitiesChildrenMeal=true";
}