import React from 'react';
import DialogTitleWithClose from "../dialogTitleWithClose";
import {
    Alert,
    Breadcrumbs,
    Button,
    CircularProgress,
    Dialog,
    Divider,
    Link,
    TextField,
    Typography
} from "@mui/material";
import {logEvent} from "firebase/analytics";
import {postApi} from "../../util/api";
import {focusByIdOnEnter, onEnter} from "../../util/input";

class EmailStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessage: null,

            email: null,
            emailTemp: null,
        }
    }

    render() {
        const { next, analytics } = this.props;
        const { errorMessage, loading, email, emailTemp } = this.state;

        const setError = (error) => this.setState({
            loading: false,
            errorMessage: error
        });

        const requestEmailVerifyButtonUp = (event) => {
            if (event.key === 'Enter') {
                requestEmailVerify();
            }
        }

        const emailUpdate = (event) => this.setState({emailTemp: event.target.value});

        const requestEmailVerify = () => {
            logEvent(analytics, "forgetPassword.email");
            const email = emailTemp;
            this.setState({
                loading: true,
                errorMessage: null,
                email: null
            });
            postApi("api/user/forgetPassword/request", {
                email: email
            }, result => {
                if (result.error) {
                    setError(result.error.message);
                }
                else {
                    this.setState({
                        loading: false,
                        email: email,
                        errorMessage: null
                    });
                }
            }, err => {
                setError(err);
            }, {"timestamp":1674148574792});
        }

        const verifyEmail = (event) => {
            const otp = event.target.value;
            if (otp.length>=7) {
                this.setState({
                    loading: true,
                    errorMessage: null
                });
                postApi("api/user/forgetPassword/check", {
                    email: email,
                    emailVerification: otp
                }, result => {
                    if (result.error) {
                        setError(result.error.message);
                    }
                    else {
                        next(email, otp);
                    }
                }, err => {
                    setError(err);
                }, {"timestamp":1674148689008});
            }
        }

        return (
            <div className="register-container">
                <div className="register-tab">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">驗証電郵地址</Typography>
                    </Breadcrumbs>
                </div>
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : <div/>}
                <div className="register-row">
                    <TextField id="standard-basic-email" className="register-field-l" fullWidth={true} label="電郵地址" required onKeyDown={requestEmailVerifyButtonUp} onChange={emailUpdate} inputProps={{
                        enterKeyHint: "go"
                    }}/>
                </div>
                <div className="register-row">
                    {email ?
                        <TextField id="standard-basic-otp" className="register-field-s" label="驗證碼" required onChange={verifyEmail}/>
                        :
                        <Button variant="outlined" className="register-btn" onClick={requestEmailVerify}>獲取驗證碼</Button>
                    }
                    {loading ?
                        <div className="loading"><CircularProgress /></div>
                        : <div/>
                    }
                </div>
            </div>
        )
    }
}

class PasswordStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            loading: null,

            password: "",
            passwordError: false,
            passwordErrorHint: null,

            confirmPassword: "",
            confirmPasswordError: false,
            confirmPasswordErrorHint: null,
        };
    }

    render() {
        const { next, back, email, otp } = this.props;
        const { errorMessage, loading,
            password, passwordError, passwordErrorHint,
            confirmPassword, confirmPasswordError, confirmPasswordErrorHint,
        } = this.state;

        const updatePassword = (event) => this.setState({password: event.target.value});
        const passwordNext = (event) => focusByIdOnEnter(event, "standard-basic-password-confirm");
        const updateConfirmPassword = (event) => this.setState({confirmPassword: event.target.value});
        const confirmPasswordNext = (event) => onEnter(event, submit);

        const setError = (error) => this.setState({
            loading: false,
            errorMessage: error
        });

        const submit = () => {
            this.setState({
                passwordError: false,
                passwordErrorHint: null,
                confirmPasswordError: false,
                confirmPasswordErrorHint: null,
            });
            if (password==="" || confirmPassword==="" || password.length<6 || confirmPassword.length<6) {
                if (password==="") this.setState({
                    passwordError: true,
                    passwordErrorHint: "必填"
                });
                else if (password.length<6) this.setState({
                    passwordError: true,
                    passwordErrorHint: "密碼最少要有6個字符長"
                });
                if (confirmPassword==="") this.setState({
                    confirmPasswordError: true,
                    confirmPasswordErrorHint: "必填"
                });
                else if (confirmPassword.length<6) this.setState({
                    confirmPasswordError: true,
                    confirmPasswordErrorHint: "密碼最少要有6個字符長"
                });
                return;
            }
            if (password!==confirmPassword) {
                this.setState({
                    confirmPasswordError: true,
                    confirmPasswordErrorHint: "密碼不符"
                });
                return;
            }
            setError(null);
            this.setState({
                loading: true
            });
            postApi("api/user/forgetPassword/submit", {
                email: email,
                emailVerification: otp,
                password: password,
            }, result => {
                if (result.error) {
                    setError(result.error.message);
                }
                else {
                    next();
                }
            }, err => {
                setError(err);
            }, {});
        }

        return (
            <div className="register-container">
                <div className="register-tab">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" onClick={back}>
                            驗証電郵地址
                        </Link>
                        <Typography color="text.primary">新密碼</Typography>
                    </Breadcrumbs>
                </div>
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : <div/>}
                <div className="forget-password-row">
                    <TextField id="standard-basic-password" fullWidth={true} label="密碼" type="password" required onChange={updatePassword} onKeyDown={passwordNext} error={passwordError} helperText={passwordErrorHint} inputProps={{
                        enterKeyHint: "next"
                    }}/>
                    <TextField id="standard-basic-password-confirm" fullWidth={true} label="確認密碼" type="password" required onChange={updateConfirmPassword} onKeyDown={confirmPasswordNext} error={confirmPasswordError} helperText={confirmPasswordErrorHint} inputProps={{
                        enterKeyHint: "go"
                    }}/>
                </div>
                <Divider/>
                {loading ?
                    <div className="loading"><CircularProgress/></div>
                    :
                    <Button variant="outlined" fullWidth={true} onClick={submit} color="primary">更改密碼</Button>
                }
            </div>
        );
    }
}

export default class ForgetPasswordContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            email: null,
            otp: null,
        };
    }

    render() {
        const { handleClose, open, analytics } = this.props;
        const { step, email, otp } = this.state;

        const emailNext = (email, otp) => {
            this.setState({
                email: email,
                otp: otp,
                step: 2,
            });
            logEvent(analytics, "forgetPassword.otp");
        }
        const passwordBack = () => {
            this.setState({
                step: 1,
            });
        };
        const passwordNext = () => {
            logEvent(analytics, "forgetPassword.submit");
            handleClose();
        };

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitleWithClose title="忘記密碼" handleClose={handleClose}/>
                <div className="register-container-outer">
                    {step===1 ?
                        <EmailStep next={emailNext} analytics={analytics}/>
                        :
                        step===2 ?
                            <PasswordStep next={passwordNext} back={passwordBack} email={email} otp={otp}/>
                            :
                            <div/>
                    }
                </div>

            </Dialog>
        )
    }
}