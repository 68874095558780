import React from 'react';
import {focusByIdOnEnter, onEnter} from "../../util/input";
import {postApi} from "../../util/api";
import {Alert, Button, CircularProgress, Dialog, DialogTitle, TextField, Typography} from "@mui/material";
import DialogTitleWithClose from "../dialogTitleWithClose";

const initialUpdatePasswordState = () => {
    return {
        loading: false,
        updated: false,
        errorMessage: null,

        password: "",
        passwordError: false,
        passwordErrorHint: null,

        confirmPassword: "",
        confirmPasswordError: false,
        confirmPasswordErrorHint: null,
    }
}

export default class UpdatePasswordContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialUpdatePasswordState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldOpen = prevProps.open;
        const newOpen = this.props.open;
        if (oldOpen && !newOpen) {
            this.setState(initialUpdatePasswordState());
        }
    }

    render() {
        const {open, handleClose} = this.props;
        const {loading, updated, errorMessage,
            password, passwordError, passwordErrorHint,
            confirmPassword, confirmPasswordError, confirmPasswordErrorHint} = this.state;

        const updatePassword = (event) => this.setState({password: event.target.value});
        const passwordNext = (event) => focusByIdOnEnter(event, "standard-basic-password-confirm");
        const updateConfirmPassword = (event) => this.setState({confirmPassword: event.target.value});
        const passwordConfirmNext = (event) => onEnter(event, submit);

        const setError = (error) => this.setState({
            loading: false,
            errorMessage: error
        });

        const submit = () => {
            this.setState({
                passwordError: false,
                passwordErrorHint: null,
                confirmPasswordError: false,
                confirmPasswordErrorHint: null,
            });
            if (password==="" || confirmPassword==="" || password.length<6 || confirmPassword.length<6) {
                if (password==="") this.setState({
                    passwordError: true,
                    passwordErrorHint: "必填"
                });
                else if (password.length<6) this.setState({
                    passwordError: true,
                    passwordErrorHint: "密碼最少要有6個字符長"
                });
                if (confirmPassword==="") this.setState({
                    confirmPasswordError: true,
                    confirmPasswordErrorHint: "必填"
                });
                else if (confirmPassword.length<6) this.setState({
                    confirmPasswordError: true,
                    confirmPasswordErrorHint: "密碼最少要有6個字符長"
                });
                return;
            }
            if (password!==confirmPassword) {
                this.setState({
                    confirmPasswordError: true,
                    confirmPasswordErrorHint: "密碼不符"
                });
                return;
            }
            this.setState({
                loading: true,
            });
            postApi('api/user/update/password', {
                password: password
            }, result => {
                if (result.error) {
                    setError(result.error.message);
                }
                else {
                    this.setState({
                        loading: false,
                        updated: true,
                    })
                }
            }, err => {
                setError(err);
            }, {"timestamp":1674147689494});
        }

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitleWithClose title="更改密碼" handleClose={handleClose}/>
                {
                    updated ?
                        <div className="password-container">
                            <Typography className="me-success-message">
                                成功更改密碼
                            </Typography>
                        </div> :
                        <div className="password-container">
                            {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : <div/>}
                            <TextField id="standard-basic-password" fullWidth={true} label="密碼" type="password" required onChange={updatePassword} onKeyDown={passwordNext} error={passwordError} helperText={passwordErrorHint} inputProps={{
                                enterKeyHint: "next"
                            }}/>
                            <TextField id="standard-basic-password-confirm" fullWidth={true} label="確認密碼" type="password" required onChange={updateConfirmPassword} onKeyDown={passwordConfirmNext} error={confirmPasswordError} helperText={confirmPasswordErrorHint} inputProps={{
                                enterKeyHint: "go"
                            }}/>

                            {loading ?
                                <div className="loading"><CircularProgress/></div> :
                                <Button variant="outlined" fullWidth={true} onClick={submit} color="primary">更改密碼</Button>
                            }
                        </div>
                }
            </Dialog>
        )
    }
}
