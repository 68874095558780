import React from "react";
import {Rating, Typography} from "@mui/material";

class RatingSmallDisplay extends React.Component {
    render() {
        const {name, rating} = this.props;

        return (
            <Typography variant="body1" color="text.secondary">
                {name}: {rating ? Math.round(rating * 10) / 10 : '/'}
            </Typography>
        );
    }
}

class RatingDisplay extends React.Component {
    render() {
        const {name, rating} = this.props;
        return (
            <div>
                <Typography variant="subtitle1">
                    {name}:
                </Typography>
                {rating ?
                    <div>
                        <Rating value={rating} precision={0.5} size="large" readOnly />
                    </div> :
                    <Typography variant="subtitle2" color="text.secondary">
                        暫時未有評分
                    </Typography>
                }
            </div>
        )
    }
}

export default class RatingDisplayBlock extends React.Component {
    render() {
        const {rating} = this.props;

        return (
            <div className="item-section">
                <RatingDisplay name="整體評分" rating={rating ? rating.overall : null}/>
                <div className="item-section-list">
                    <RatingSmallDisplay name="食物合適度" rating={rating ? rating.food : null}/>
                    <RatingSmallDisplay name="座位寬敞度" rating={rating ? rating.crowdedness : null}/>
                    <RatingSmallDisplay name="座位舒適度" rating={rating ? rating.seats : null}/>
                </div>
            </div>
        );
    };
}