import React from "react";
import {Button, CircularProgress, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ReviewContainer from "./review";
import ReviewListContainer from "./reviewList";
import RatingDisplayBlock from "./ratingDisplay";
import {getApi, postApi} from "../util/api";
import {logEvent} from "firebase/analytics";
import {logScreenView} from "../util/analytics";

class FacilityDisplay extends React.Component {
    render() {
        const {name, facility} = this.props;
        return (
            <div className="item-section">
                <Typography variant="body1" color="text.secondary">
                    {name}:
                </Typography>
                <Typography variant="body1" color="text.secondary" className="facility-row">
                    {facility && facility.exist === "yes" ?
                        <CheckCircleIcon className="facility-yes-icon"/> :
                        <CheckCircleOutlineIcon/>
                    }
                    {facility ? facility.yesCount : 0}
                    {facility && facility.exist === "no" ?
                        <CancelIcon className="facility-no-icon"/> :
                        <CancelOutlinedIcon/>
                    }
                    {facility ? facility.noCount : 0}
                </Typography>
            </div>
        );
    }
}

export default class SearchResultEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openReviewList: false,
            initReviewList: false,
            openReview: false,
            initReview: false,

            otherShopCountLoaded: false,
            otherShopCount: 0,
            otherShopLoading: false,
        }
    }

    componentDidMount() {
        const { item, analytics } = this.props;
        logEvent(analytics, 'poi.display', {isOtherShop: item.isOtherShop});
        if (!item.isOtherShop && !item.skipOtherShop) {
            getApi("api/poi/otherShops/count?id=" + item.id, (result) => {
                if (result.data) {
                    this.setState({
                        otherShopCountLoaded: true,
                        otherShopCount: result.data.count,
                    });
                }
            }, (error) => {
            }, {"timestamp": 1674232695909, "data": {"count": 3}});
        }
    }

    render() {
        const { item, loginResult, setOpenLogin, handleUpdateSuccess, handleLoadOtherShops, handleBookmarkFunc, analytics } = this.props;
        const { openReviewList, initReviewList, openReview, initReview, otherShopCountLoaded, otherShopCount, otherShopClicked, otherShopLoading } = this.state;

        const handleOpenReviewList = () => {
            logScreenView(analytics, 'review.list', {poiId: item.id});
            this.setState({
                openReviewList: true,
                initReviewList: true,
            });
        }
        const handleCloseReviewList = () => this.setState({openReviewList: false});

        const handleOpenReview = () => {
            logScreenView(analytics, 'review', {poiId: item.id, hasLogin: loginResult!==null});
            if (loginResult)
                this.setState({
                    openReview: true,
                    initReview: true,
                });
            else
                setOpenLogin(true);
        }
        const handleCloseReview = () => this.setState({openReview: false});

        const handleLoadOtherShopsDo = () => {
            this.setState({
                otherShopClicked: true,
                otherShopLoading: true,
            });
            handleLoadOtherShops(item.id, setOtherShopLoading);
        }

        const setOtherShopLoading = (value) => this.setState({otherShopLoading: value});

        const handleBookmark = () => {
            if (loginResult)
                postApi('api/poi/bookmark/add', {
                    id: item.id
                }, result => {
                    if (!result.error) handleBookmarkFunc(item.id, true);
                }, err => {
                }, {});
            else
                setOpenLogin(true);
        }

        const handleUnbookmark = () => {
            if (loginResult)
                postApi('api/poi/bookmark/remove', {
                    id: item.id
                }, result => {
                    if (!result.error) handleBookmarkFunc(item.id, false);
                }, err => {
                }, {});
            else
                setOpenLogin(true);
        }

        return (
            <div className="search-result-container">
                <div className="item-section">
                    <Typography variant="h5" className="search-result-title">
                        {item.bookmarked ? <FavoriteIcon className="bookmark-icon mouse-pointer" style={{ color: '#fc0349' }} onClick={handleUnbookmark}/> : <FavoriteBorderIcon className="bookmark-icon mouse-pointer"style={{ color: '#fc0349' }} onClick={handleBookmark}/>}
                        {item.isOtherShop ? "[分店] " : ""}{item.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {item.address}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {item.website}
                    </Typography>
                </div>
                <RatingDisplayBlock rating={item.ratingAverage}/>
                <div className="item-section">
                    <Typography variant="subtitle1">
                        設施:
                    </Typography>
                    <div className="item-section-list">
                        <FacilityDisplay name="BB凳/高凳" facility={item.facilities.highChair}/>
                        <FacilityDisplay name="兒童餐" facility={item.facilities.childrenMeal}/>
                    </div>
                </div>
                <div className="item-section">
                    <Button onClick={handleOpenReviewList} disabled={item.totalReview<=0}>共 {item.totalReview} 個評分</Button>
                    <ReviewListContainer item={item} open={openReviewList} handleClose={handleCloseReviewList} init={initReviewList} analytics={analytics}/>
                </div>
                <div className="review-btn-container">
                    <Button variant="outlined" className="review-btn" onClick={handleOpenReview}>評分</Button>
                    <ReviewContainer item={item} open={openReview} handleClose={handleCloseReview} init={initReview} handleUpdateSuccess={handleUpdateSuccess} analytics={analytics}/>
                    {
                        otherShopLoading ? <div className="loading"><CircularProgress /></div> :
                        !item.isOtherShop && otherShopCountLoaded && !otherShopClicked ?
                            <Button color="success" onClick={handleLoadOtherShopsDo} disabled={otherShopCount<=0}>顯示其他 {otherShopCount} 間有評分的分店</Button>
                            : <div/>
                    }
                </div>
            </div>
        )
    }
}
