import React from 'react';
import {Divider, Menu, MenuItem, Typography} from "@mui/material";
import {postApi} from "../../util/api";

class MeMenuItem extends React.Component {
    render() {
        const {value, handleClick, disabled} = this.props;
        return (
            <MenuItem onClick={handleClick} disabled={disabled}><Typography color="text.secondary" variant="subtitle1">{value}</Typography></MenuItem>
        );
    }
}

export default class MeMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggingOut: false,
        };
    }

    render() {
        const {anchorEl, handleCloseMenu, handleOpenMe, handleOpenUpdatePassword, handleOpenMyReview, handleOpenMyBookmark, setLoginResult} = this.props;
        const {loggingOut} = this.state;

        const openMenu = Boolean(anchorEl);

        const handleLogout = () => {
            this.setState({loggingOut: true});
            postApi('api/user/logout', {}, result => {
                setLoginResult(null);
                handleCloseMenu();
                this.setState({loggingOut: false});
            }, err => {

            }, {});
        }

        return (
            <Menu
                id="me-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MeMenuItem handleClick={handleOpenMe} value="用戶資料"/>
                <MeMenuItem handleClick={handleOpenUpdatePassword} value="更改密碼"/>
                <MeMenuItem handleClick={handleOpenMyReview} value="評分紀錄"/>
                <MeMenuItem handleClick={handleOpenMyBookmark} value="我的最愛"/>
                <Divider />
                {loggingOut ?
                    <MeMenuItem handleClick={handleLogout} value="登出" disabled={true}/> :
                    <MeMenuItem handleClick={handleLogout} value="登出"/>
                }
            </Menu>
        );
    }

}