import React from 'react';
import {Avatar, Button} from "@mui/material";
import LoginContainer from "./login";
import RegisterContainer from "./register";
import {getApi} from "../../util/api";
import {getSrc} from "../../util/file";
import MeMenu from "./menu";
import UpdatePasswordContainer from "./password";
import MeContainer from "./me";
import MyReviewListContainer from "../myReviewList";
import MyBookmarkListContainer from "../myBookmarkList";
import {logScreenView} from "../../util/analytics";
import ForgetPasswordContainer from "./forgetPassword";

export default class LoginAvatar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loginEmail: null,
            loginPassword: null,
            loginError: null,
            openRegister: false,
            openForgetPassword: false,

            anchorEl: null,
            openUpdatePassword: false,
            openMe: false,
            openMyReview: false,
            initReviewList: false,
            openMyBookmark: false,
            initBookmarkList: false,
        };
        getApi("api/user/me", (result) => {
            if (result.data)
                this.props.setLoginResult(result.data);
            this.setState({loading: false});
        }, (error) => {
            this.setState({loading: false});
        }, {"timestamp":1674147689494,"data":{"id":"63adc7b06ac3425eb22fe363","email":"anita1@email.com","name":"Anita 1","profilePic":{"id":"63bc4b897bf89ee93323eed3","filename":"NFRewards.png","contentType":"image/png","fileSize":4693,"url":"api/assets/upp/63bc4b897bf89ee93323eed3/NFRewards.png"},"numberOfChild":"1","childAge0":false,"childAge1":true,"childAge2":false,"status":"active"}});
    }

    render() {
        const { loginResult, setLoginResult, openLogin, setOpenLogin, locationTags, analytics } = this.props;
        const { loading, openRegister, openForgetPassword, anchorEl, openUpdatePassword, openMe, openMyReview, initReviewList, openMyBookmark, initBookmarkList } = this.state;

        const handleOpenMenu = (event) => this.setState({anchorEl: event.currentTarget});
        const handleCloseMenu = () => this.setState({anchorEl: null});

        const handleOpenUpdatePassword = () => this.setState({openUpdatePassword: true, anchorEl: null});
        const handleCloseUpdatePassword = () => this.setState({openUpdatePassword: false, anchorEl: null});

        const handleOpenLogin = () => {
            this.setState({openRegister: false});
            setOpenLogin(true);
        }
        const handleCloseLogin = () => setOpenLogin(false);

        const handleOpenRegister = () => {
            this.setState({openRegister: true});
            setOpenLogin(false);
        }
        const handleCloseRegister = () => this.setState({openRegister: false});

        const handleOpenForgetPassword = () => {
            this.setState({openForgetPassword: true});
            setOpenLogin(false);
        }
        const handleCloseForgetPassword = () => this.setState({openForgetPassword: false});

        const handleOpenMe = () => {
            this.setState({openMe: true, anchorEl: null});
            logScreenView(analytics, "me");
        }
        const handleCloseMe = () => this.setState({openMe: false, anchorEl: null});

        const handleOpenMyReview = () => {
            this.setState({openMyReview: true, initReviewList: true, anchorEl: null});
            logScreenView(analytics, "myReview");
        }
        const handleCloseMyReview = () => this.setState({openMyReview: false, initReviewList: false, anchorEl: null});

        const handleOpenMyBookmark = () => {
            this.setState({openMyBookmark: true, initBookmarkList: true, anchorEl: null});
            logScreenView(analytics, "myBookmark");
        }
        const handleCloseMyBookmark = () => this.setState({openMyBookmark: false, initBookmarkList: false, anchorEl: null});

        return (
            <div>
                {loading ?
                    <Button disabled={true}>登入中...</Button> :
                    loginResult ?
                    <div>
                        <Avatar onClick={handleOpenMenu} src={getSrc(loginResult.profilePic)} className="mouse-pointer">{loginResult.name.substring(0, 1)}</Avatar>
                        <MeMenu anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} handleOpenMe={handleOpenMe} handleOpenUpdatePassword={handleOpenUpdatePassword} handleOpenMyReview={handleOpenMyReview} handleOpenMyBookmark={handleOpenMyBookmark} setLoginResult={setLoginResult}/>
                        <MeContainer handleClose={handleCloseMe} open={openMe} loginResult={loginResult} setLoginResult={setLoginResult}/>
                        <UpdatePasswordContainer open={openUpdatePassword} handleClose={handleCloseUpdatePassword}/>
                        <MyReviewListContainer open={openMyReview} handleClose={handleCloseMyReview} init={initReviewList} analytics={analytics}/>
                        <MyBookmarkListContainer open={openMyBookmark} handleClose={handleCloseMyBookmark} init={initBookmarkList} loginResult={loginResult} setOpenLogin={setOpenLogin} locationTags={locationTags} analytics={analytics}/>
                    </div>
                    :
                    <div>
                        <Button onClick={handleOpenLogin}>登入 / 註冊</Button>
                        <LoginContainer open={openLogin} handleClose={handleCloseLogin} handleOpenRegister={handleOpenRegister}  handleOpenForgetPassword={handleOpenForgetPassword} setLoginResult={setLoginResult}/>
                        <ForgetPasswordContainer open={openForgetPassword} handleClose={handleCloseForgetPassword} analytics={analytics}/>
                        <RegisterContainer open={openRegister} handleClose={handleCloseRegister} handleOpenLogin={handleOpenLogin} setLoginResult={setLoginResult} analytics={analytics}/>
                    </div>
                }
            </div>
        );
    }
}
