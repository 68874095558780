import React from "react";
import {DialogTitle} from "@mui/material";
import CloseButton from "./closeButton";

export default class DialogTitleWithClose extends React.Component {
    render() {
        const {title, handleClose} = this.props;

        return (
            <DialogTitle>
                <div className="dialog-close">
                    <div>{title}</div>
                    <CloseButton handleClose={handleClose}/>
                </div>
            </DialogTitle>
        )
    }
}