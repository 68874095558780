export const onEnter = (event, callback) => {
    if (event.key === 'Enter') callback();
}

export const focusById = (id) => {
    document.querySelector(`input[id=`+id+`]`).focus()
}

export const focusByIdOnEnter = (event, id) => {
    onEnter(event, ()=> focusById(id));
}